import { AddShoppingCartOutlined } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Rating,
  Typography,
} from "@mui/material";
import React from "react";
import "./ProductCard.css";

const ProductCard = ({ product, handleAddToCart }) => {
  const { name, category, cost, rating, image, _id: productId } = product;
  return (
    <Card className="card">
      <CardMedia component="img" image={image} alt={name} />
      <CardContent>
        <Typography gutterBottom variant="h6">
          {name}
        </Typography>
        <Typography gutterBottom variant="h6" className="cost-text">
          ${cost}
        </Typography>
        <Rating name="rating" value={rating} readOnly />
      </CardContent>

      <CardActions className="card-actions">
        <Button
          variant="contained"
          className="card-button"
          startIcon={<AddShoppingCartOutlined />}
          fullWidth
          onClick={() => handleAddToCart(productId)}
        >
          ADD TO CART
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
