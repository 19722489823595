import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { config } from "../App";
import Footer from "./Footer";
import Header from "./Header";
import "./Register.css";
import { useHistory, Link } from "react-router-dom";

const Register = () => {
  const { enqueueSnackbar } = useSnackbar();

  const generateSnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
      }
  });
  }

  const registrationDetailsInit = {
    username: "",
    password: "",
    confirmPassword: ""
  }

  const [registrationDetails, setRegistrationDetails] = useState(registrationDetailsInit)
  const [registrationInProgress, setRegistrationInProgress] = useState(false)


  // TODO: CRIO_TASK_MODULE_REGISTER - Implement the register function
  /**
   * Definition for register handler
   * - Function to be called when the user clicks on the register button or submits the register form
   *
   * @param {{ username: string, password: string, confirmPassword: string }} formData
   *  Object with values of username, password and confirm password user entered to register
   *
   * API endpoint - "POST /auth/register"
   *
   * Example for successful response from backend for the API call:
   * HTTP 201
   * {
   *      "success": true,
   * }
   *
   * Example for failed response from backend for the API call:
   * HTTP 400
   * {
   *      "success": false,
   *      "message": "Username is already taken"
   * }
   */
  const history = useHistory();

  const register = async (formData) => {
    setRegistrationInProgress(true)
    const {username, password} = formData
    try{
      const response = await axios.post(`${config.endpoint}/auth/register`, {username, password})
      console.log(response)
      generateSnackBar("Registered successfully", 'success')
      setRegistrationDetails(registrationDetailsInit)
      setRegistrationInProgress(false)
      history.push("/login") // redirects to products page
    }catch(error) {
      let errorMessage = "Something went wrong. Check that the backend is running, reachable and returns valid JSON."
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data); //eg {success: false, message: 'Username already exists'}
        console.log(`Status code: ${error.response.status}`); //eg: 400 (status code) 
        if (error.response.status === 400){
          errorMessage = error.response.data.message
        }
      } else if (error.request) {
        // The request was made but no response was received
        //===backend not started
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log(`Error: ${error.message}`);
      }
      generateSnackBar(errorMessage, 'error')
      setRegistrationInProgress(false)
    }
  };

  // TODO: CRIO_TASK_MODULE_REGISTER - Implement user input validation logic
  /**
   * Validate the input values so that any bad or illegal values are not passed to the backend.
   *
   * @param {{ username: string, password: string, confirmPassword: string }} data
   *  Object with values of username, password and confirm password user entered to register
   *
   * @returns {boolean}
   *    Whether validation has passed or not
   *
   * Return false if any validation condition fails, otherwise return true.
   * (NOTE: The error messages to be shown for each of these cases, are given with them)
   * -    Check that username field is not an empty value - "Username is a required field"
   * -    Check that username field is not less than 6 characters in length - "Username must be at least 6 characters"
   * -    Check that password field is not an empty value - "Password is a required field"
   * -    Check that password field is not less than 6 characters in length - "Password must be at least 6 characters"
   * -    Check that confirmPassword field has the same value as password field - Passwords do not match
   */
  const validateInput = (data) => {
    const {username, password, confirmPassword} = data
    if (username===""){
      generateSnackBar("Username is a required field", 'warning')
      return false
    }else if (username.length < 6){
      generateSnackBar("Username must be at least 6 characters", 'warning')
      return false
    }
    if (password===""){
      generateSnackBar("Password is a required field", 'warning')
      return false
    }else if(password.length<6){
      generateSnackBar("Password must be at least 6 characters", 'warning')
      return false
    }else if(password!==confirmPassword){
      generateSnackBar("Passwords do not match", 'warning')
      return false
    }
    return (true)
  };

  const handleInputChange = (e) =>{
    const name = e.target.name
    const value = e.target.value
    setRegistrationDetails({...registrationDetails, [name]:value})
  }

  const handleRegistration = () =>{
    console.log(registrationDetails)
    console.log(`Valid Input: ${validateInput(registrationDetails)}`)
    const isValidInput = validateInput(registrationDetails)
    if (isValidInput){
      register(registrationDetails)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight="100vh"
    >
      <Header hasHiddenAuthButtons />
      <Box className="content">
        <Stack spacing={2} className="form">
          <h2 className="title">Register</h2>
          <TextField
            id="username"
            label="Username"
            variant="outlined"
            title="Username"
            name="username"
            value={registrationDetails.username}
            onChange={handleInputChange}
            placeholder="Enter Username"
            fullWidth
          />
          <TextField
            id="password"
            variant="outlined"
            label="Password"
            name="password"
            value={registrationDetails.password}
            onChange={handleInputChange}
            type="password"
            helperText="Password must be atleast 6 characters length"
            fullWidth
            placeholder="Enter a password with minimum 6 characters"
          />
          <TextField
            id="confirmPassword"
            variant="outlined"
            label="Confirm Password"
            name="confirmPassword"
            value={registrationDetails.confirmPassword}
            onChange={handleInputChange}
            type="password"
            fullWidth
          />

          {registrationInProgress? <Stack alignItems="center"> <CircularProgress /></Stack>
           : <Button className="button" variant="contained" onClick={handleRegistration} >
            Register Now
           </Button>
          }

          <p className="secondary-action">
            Already have an account?{" "}
             <Link to="/login" className="link">Login here</Link>
          </p>
        </Stack>
      </Box>
      <Footer />
    </Box>
  );
};

export default Register;
