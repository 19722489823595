import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { config } from "../App";
import Footer from "./Footer";
import Header from "./Header";
import "./Login.css";

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const generateSnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
      }
  });
  }

  const loginDetailsInit = {
    username: "",
    password: ""
  }

  const [loginDetails, setLoginDetails] = useState(loginDetailsInit)
  const [loginInProgress, setLoginInProgress] = useState(false)

  // TODO: CRIO_TASK_MODULE_LOGIN - Fetch the API response
  /**
   * Perform the Login API call
   * @param {{ username: string, password: string }} formData
   *  Object with values of username, password and confirm password user entered to register
   *
   * API endpoint - "POST /auth/login"
   *
   * Example for successful response from backend:
   * HTTP 201
   * {
   *      "success": true,
   *      "token": "testtoken",
   *      "username": "criodo",
   *      "balance": 5000
   * }
   *
   * Example for failed response from backend:
   * HTTP 400
   * {
   *      "success": false,
   *      "message": "Password is incorrect"
   * }
   *
   */
  const history = useHistory()

  const login = async (formData) => {
    setLoginInProgress(true)
    const {username, password} = formData
    try{
      const response = await axios.post(`${config.endpoint}/auth/login`, {username, password})
      persistLogin(response.data.token,response.data.username, response.data.balance)
      generateSnackBar("Logged in successfully", 'success')
      setLoginDetails(loginDetailsInit)
      setLoginInProgress(false)
      history.push("/") // redirects to products page
    }catch(error) {
      let errorMessage = "Something went wrong. Check that the backend is running, reachable and returns valid JSON."
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data); //eg {success: false, message: 'Username does not exist'}
        console.log(`Status code: ${error.response.status}`); //eg: 400 (status code) 
        if (error.response.status === 400){
          errorMessage = error.response.data.message
        }
      } else if (error.request) {
        // The request was made but no response was received
        //===backend not started
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log(`Error: ${error.message}`);
      }
      generateSnackBar(errorMessage, 'error')
      setLoginInProgress(false)
    }}

  // TODO: CRIO_TASK_MODULE_LOGIN - Validate the input
  /**
   * Validate the input values so that any bad or illegal values are not passed to the backend.
   *
   * @param {{ username: string, password: string }} data
   *  Object with values of username, password and confirm password user entered to register
   *
   * @returns {boolean}
   *    Whether validation has passed or not
   *
   * Return false and show warning message if any validation condition fails, otherwise return true.
   * (NOTE: The error messages to be shown for each of these cases, are given with them)
   * -    Check that username field is not an empty value - "Username is a required field"
   * -    Check that password field is not an empty value - "Password is a required field"
   */
  const validateInput = (data) => {
    const {username, password} = data
    if (username===""){
      generateSnackBar("Username is a required field", 'warning')
      return false
    }
    if (password===""){
      generateSnackBar("Password is a required field", 'warning')
      return false
    }
    return (true)
  };

  // TODO: CRIO_TASK_MODULE_LOGIN - Persist user's login information
  /**
   * Store the login information so that it can be used to identify the user in subsequent API calls
   *
   * @param {string} token
   *    API token used for authentication of requests after logging in
   * @param {string} username
   *    Username of the logged in user
   * @param {string} balance
   *    Wallet balance amount of the logged in user
   *
   * Make use of localStorage: https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
   * -    `token` field in localStorage can be used to store the Oauth token
   * -    `username` field in localStorage can be used to store the username that the user is logged in as
   * -    `balance` field in localStorage can be used to store the balance amount in the user's wallet
   */
  const persistLogin = (token, username, balance) => {
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
    localStorage.setItem('balance', balance);
  };

  const handleInputChange = (e) =>{
    const name = e.target.name
    const value = e.target.value
    setLoginDetails({...loginDetails, [name]:value})
  }
  const handleLogin = () =>{
    console.log(`Valid Input: ${validateInput(loginDetails)}`)
    const isValidInput = validateInput(loginDetails)
    if (isValidInput){
      login(loginDetails)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight="100vh"
    >
      <Header hasHiddenAuthButtons/>
      <Box className="content">
        <Stack spacing={2} className="form">
          <h2 className="title">Login</h2>
          <TextField
            id="username"
            label="Username"
            variant="outlined"
            title="Username"
            name="username"
            placeholder="Enter Username"
            value={loginDetails.username}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            id="password"
            label="Password"
            variant="outlined"
            title="Password"
            name="password"
            placeholder="Enter Password"
            value={loginDetails.password}
            onChange={handleInputChange}
            type="password"
            fullWidth
          />
          
          {loginInProgress? <Stack alignItems="center"> <CircularProgress /></Stack>
           : <Button className="button" variant="contained" onClick={handleLogin} >
            LOGIN TO QKART
           </Button>
          }
        
          <p className="secondary-action">
            Don't have an account?{" "}
             <Link to="/register" className="link">Register now</Link>
          </p>
        </Stack>
      </Box>
      <Footer />
    </Box>
  );
};

export default Login;
