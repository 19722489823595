import { Search, SentimentDissatisfied } from "@mui/icons-material";
import {
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { config } from "../App";
import Footer from "./Footer";
import Header from "./Header";
import "./Products.css";
import ProductCard from "./ProductCard";
import Cart, { generateCartItemsFrom } from "./Cart";

// Definition of Data Structures used
/**
 * @typedef {Object} Product - Data on product available to buy
 *
 * @property {string} name - The name or title of the product
 * @property {string} category - The category that the product belongs to
 * @property {number} cost - The price to buy the product
 * @property {number} rating - The aggregate rating of the product (integer out of five)
 * @property {string} image - Contains URL for the product image
 * @property {string} _id - Unique ID for the product
 *
 * @typedef {Object} CartItem -  - Data on product added to cart
 * @property {string} name - The name or title of the product in cart
 * @property {string} qty - The quantity of product added to cart
 * @property {string} productId - Unique ID for the product
 */

const Products = () => {
  const [productsList, setProductsList] = useState([]);
  const [allProductsList, setAllProductsList] = useState([]);
  const [cartedProductsList, setCartedProductsList] = useState([]);
  const [productsNotLoaded, setProductsNotLoaded] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [timer, setTimer] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const generateSnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  // TODO: CRIO_TASK_MODULE_PRODUCTS - Fetch products data and store it
  /**
   * Make API call to get the products list and store it to display the products
   *
   * @returns { Array.<Product> }
   *      Array of objects with complete data on all available products
   *
   * API endpoint - "GET /products"
   *
   * Example for successful response from backend:
   * HTTP 200
   * [
   *      {
   *          "name": "iPhone XR",
   *          "category": "Phones",
   *          "cost": 100,
   *          "rating": 4,
   *          "image": "https://i.imgur.com/lulqWzW.jpg",
   *          "_id": "v4sLtEcMpzabRyfx"
   *      },
   *      {
   *          "name": "Basketball",
   *          "category": "Sports",
   *          "cost": 100,
   *          "rating": 5,
   *          "image": "https://i.imgur.com/lulqWzW.jpg",
   *          "_id": "upLK9JbQ4rMhTwt4"
   *      }
   * ]
   *
   * Example for failed response from backend:
   * HTTP 500
   * {
   *      "success": false,
   *      "message": "Something went wrong. Check the backend console for more details"
   * }
   */
  const performAPICall = async () => {
    setProductsNotLoaded(true);
    try {
      const response = await axios.get(`${config.endpoint}/products`);
      setProductsList(response.data);
      setAllProductsList(response.data);
    } catch (error) {
      let errorMessage =
        "Something went wrong. Check the backend console for more details";
      if (error.response) {
        const errorStatusCode = error.response.status;
        const errorStatusText = error.response.statusText;
        errorMessage = `Status Code: ${errorStatusCode}; Error Message: "${errorStatusText}"`;
      }
      generateSnackBar(errorMessage, "error");
      setProductsList([]);
      setAllProductsList([]);
    }
    setProductsNotLoaded(false);
  };

  useEffect(() => {
    performAPICall();
  }, []);

  // TODO: CRIO_TASK_MODULE_PRODUCTS - Implement search logic
  /**
   * Definition for search handler
   * This is the function that is called on adding new search keys
   *
   * @param {string} text
   *    Text user types in the search bar. To filter the displayed products based on this text.
   *
   * @returns { Array.<Product> }
   *      Array of objects with complete data on filtered set of products
   *
   * API endpoint - "GET /products/search?value=<search-query>"
   *
   */
  const performSearch = async (text) => {
    console.log(text);
    if (text === "") {
      performAPICall();
      return;
    }
    let searchQuery = encodeURI(text.trim());
    try {
      const response = await axios.get(
        `${config.endpoint}/products/search?value=${searchQuery}`
      );
      setProductsList(response.data);
    } catch (error) {
      // here the error could only mean no matching products are available for current search
      setProductsList([]);
    }
  };

  // TODO: CRIO_TASK_MODULE_PRODUCTS - Optimise API calls with debounce search implementation
  /**
   * Definition for debounce handler
   * With debounce, this is the function to be called whenever the user types text in the searchbar field
   *
   * @param {{ target: { value: string } }} event
   *    JS event object emitted from the search input field
   *
   * @param {NodeJS.Timeout} debounceTimeout
   *    Timer id set for the previous debounce call
   *
   */
  const debounceSearch = (event, debounceTimeout = 500) => {
    clearTimeout(timer);
    setSearchText(event.target.value);
    const newTimer = setTimeout(() => {
      performSearch(event.target.value);
    }, debounceTimeout);
    setTimer(newTimer);
  };

  const isLoggedIn = () => Boolean(localStorage.getItem("username"));

  /**
   * Perform the API call to fetch the user's cart and return the response
   *
   * @param {string} token - Authentication token returned on login
   *
   * @returns { Array.<{ productId: string, qty: number }> | null }
   *    The response JSON object
   *
   * Example for successful response from backend:
   * HTTP 200
   * [
   *      {
   *          "productId": "KCRwjF7lN97HnEaY",
   *          "qty": 3
   *      },
   *      {
   *          "productId": "BW0jAAeDJmlZCF8i",
   *          "qty": 1
   *      }
   * ]
   *
   * Example for failed response from backend:
   * HTTP 401
   * {
   *      "success": false,
   *      "message": "Protected route, Oauth2 Bearer token not found"
   * }
   */
  useEffect(() => {
    (async () => {
      const cartedProductsInfo = await fetchCart(localStorage.getItem("token"));
      if (cartedProductsInfo) {
        const cartDetails = generateCartItemsFrom(
          cartedProductsInfo,
          allProductsList
        );
        setCartedProductsList(cartDetails);
      }
    })();
  }, [allProductsList]);

  const fetchCart = async (token) => {
    if (!token || allProductsList.length === 0) return;

    try {
      // TODO: CRIO_TASK_MODULE_CART - Pass Bearer token inside "Authorization" header to get data from "GET /cart" API and return the response data
      const response = await axios.get(`${config.endpoint}/cart`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const cartedProductsInfo = response.data;
      return cartedProductsInfo;
    } catch (e) {
      if (e.response && e.response.status === 400) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar(
          "Could not fetch cart details. Check that the backend is running, reachable and returns valid JSON.",
          {
            variant: "error",
          }
        );
      }
      return null;
    }
  };

  // TODO: CRIO_TASK_MODULE_CART - Return if a product already exists in the cart
  /**
   * Return if a product already is present in the cart
   *
   * @param { Array.<{ productId: String, quantity: Number }> } items
   *    Array of objects with productId and quantity of products in cart
   * @param { String } productId
   *    Id of a product to be checked
   *
   * @returns { Boolean }
   *    Whether a product of given "productId" exists in the "items" array
   *
   */
  const isItemInCart = (items, productId) => {
    const answer = items.some((item) => item._id === productId);
    return answer;
  };

  /**
   * Perform the API call to add or update items in the user's cart and update local cart data to display the latest cart
   *
   * @param {string} token
   *    Authentication token returned on login
   * @param { Array.<{ productId: String, quantity: Number }> } items
   *    Array of objects with productId and quantity of products in cart
   * @param { Array.<Product> } products
   *    Array of objects with complete data on all available products
   * @param {string} productId
   *    ID of the product that is to be added or updated in cart
   * @param {number} qty
   *    How many of the product should be in the cart
   * @param {boolean} options
   *    If this function was triggered from the product card's "Add to Cart" button
   *
   * Example for successful response from backend:
   * HTTP 200 - Updated list of cart items
   * [
   *      {
   *          "productId": "KCRwjF7lN97HnEaY",
   *          "qty": 3
   *      },
   *      {
   *          "productId": "BW0jAAeDJmlZCF8i",
   *          "qty": 1
   *      }
   * ]
   *
   * Example for failed response from backend:
   * HTTP 404 - On invalid productId
   * {
   *      "success": false,
   *      "message": "Product doesn't exist"
   * }
   */
  const addToCart = async (
    productId,
    qty,
    fromAddToCart = false,
    token = localStorage.getItem("token"),
    items = cartedProductsList,
    // products=allProductsList,
    options = { preventDuplicate: false }
  ) => {
    if (!token) {
      enqueueSnackbar("Login to add an item to the Cart", {
        variant: "warning",
      });
      return;
    }
    if (fromAddToCart && isItemInCart(items, productId)) {
      enqueueSnackbar(
        "Item already in cart. Use the cart sidebar to update quantity or remove item.",
        {
          variant: "warning",
        }
      );
      return;
    }
    //control reaches here if the user is logged in and cart needs updation
    //make api POST request

    try {
      // TODO: CRIO_TASK_MODULE_CART - Pass Bearer token inside "Authorization" header to get data from "GET /cart" API and return the response data
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const payLoadData = { productId, qty };
      const response = await axios.post(
        `${config.endpoint}/cart`,
        payLoadData,
        {
          headers: headers,
        }
      );
      const updatedCartedProductsInfo = response.data;
      const cartDetails = generateCartItemsFrom(
        updatedCartedProductsInfo,
        allProductsList
      );
      setCartedProductsList(cartDetails);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar(
          "Could not update cart details. Check that the backend is running, reachable and returns valid JSON.",
          {
            variant: "error",
          }
        );
      }
      return null;
    }
  };

  return (
    <div>
      <Header>
        {/* TODO: CRIO_TASK_MODULE_PRODUCTS - Display search bar in the header for Products page */}
        {/* Search view for desktops */}
        <TextField
          className="search-desktop"
          size="small"
          style={{ width: 400 }}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search color="primary" />
              </InputAdornment>
            ),
          }}
          placeholder="Search for items/categories"
          name="search"
          value={searchText}
          onChange={(e) => debounceSearch(e)}
        />
      </Header>
      {/* Search view for mobiles */}
      <TextField
        className="search-mobile"
        size="small"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search color="primary" />
            </InputAdornment>
          ),
        }}
        placeholder="Search for items/categories"
        name="search"
        value={searchText}
        onChange={(e) => debounceSearch(e)}
      />
      <Grid container>
        <Grid item className="product-grid" xs>
          <Box className="hero">
            <p className="hero-heading">
              India’s <span className="hero-highlight">FASTEST DELIVERY</span>{" "}
              to your door step
            </p>
          </Box>
          {productsNotLoaded ? (
            <Box className="loading">
              <CircularProgress />
              <p>Loading Products..</p>
            </Box>
          ) : productsList.length === 0 ? (
            <Box className="loading" color="text.secondary">
              <SentimentDissatisfied />
              <p>No products found</p>
            </Box>
          ) : (
            <Grid
              container
              spacing={2.5}
              rowSpacing={3}
              py={4}
              px={{ xs: 1, sm: 3 }}
            >
              {productsList.map((product) => (
                <Grid item xs={6} md={3} key={product._id}>
                  <ProductCard
                    product={product}
                    handleAddToCart={(
                      productId,
                      quantity = 1,
                      fromAddToCart = true
                    ) => addToCart(productId, quantity, fromAddToCart)}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        {/* TODO: CRIO_TASK_MODULE_CART - Display the Cart component */}
        {isLoggedIn() && (
          <Grid item xs={12} md={3} className="cart-area">
            <Cart
              products={allProductsList}
              items={cartedProductsList}
              handleQuantity={(productId, qty) => addToCart(productId, qty)}
            />
          </Grid>
        )}
      </Grid>
      <Footer />
    </div>
  );
};

export default Products;
