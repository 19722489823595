import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar, Button, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { useHistory } from "react-router-dom";
import "./Header.css";

const Header = ({ children, hasHiddenAuthButtons }) => {
  const history = useHistory();
  const isLoggedIn = Boolean(localStorage.getItem("username"));

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const redirectToPath = (path = "") => {
    history.push(`/${path}`);
  };

  return (
    <Box className="header">
      <Box className="header-title">
        <img src="logo_light.svg" alt="QKart-icon"></img>
      </Box>

      <Box>
        {children}
      </Box>
      
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1.5}
      >
        {hasHiddenAuthButtons && (
          <Button
            className="explore-button"
            startIcon={<ArrowBackIcon />}
            variant="text"
            onClick={() => redirectToPath()}
          >
            Back to explore
          </Button>
        )}

        {!hasHiddenAuthButtons && isLoggedIn && (
          <>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Avatar alt={localStorage.getItem("username")} src="avatar.png" />
              <p>{localStorage.getItem("username")}</p>
            </Stack>

            <Button variant="text" onClick={handleLogout}>
              Logout
            </Button>
          </>
        )}

        {!hasHiddenAuthButtons && !isLoggedIn && (
          <>
            <Button variant="text" onClick={() => redirectToPath("login")}>
              Login
            </Button>
            <Button
              variant="contained"
              onClick={() => redirectToPath("register")}
            >
              Register
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default Header;
